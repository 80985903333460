import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import DownloadButton from './DownloadButton';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const imageVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15
    }
  }
};

const GeneratedImages = ({ images }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Scroll to the generated images section when it appears
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <motion.div
      ref={containerRef}
      className="min-h-screen w-full bg-background pt-24"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="mx-auto max-w-4xl px-4 py-8">
        <motion.div 
          className="space-y-8"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.h2 
            className="text-2xl font-bold text-center text-white"
            variants={imageVariants}
          >
            Generated Images
          </motion.h2>
          
          <div className="space-y-8">
            {images.map((imageUrl, index) => (
              <motion.div
                key={index}
                className="relative overflow-hidden rounded-lg bg-white/5 p-4"
                variants={imageVariants}
              >
                <motion.img
                  src={imageUrl}
                  alt={`Generated ${index + 1}`}
                  className="w-full rounded-lg shadow-xl"
                  layoutId={`generated-image-${index}`}
                />
                <div className="absolute bottom-6 right-6">
                  <DownloadButton imageUrl={imageUrl} index={index} />
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default GeneratedImages; 