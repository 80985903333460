import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LeonardoParams from '../components/LeonardoParams';
import { useApi } from '../hooks/useApi';
import { useCredits } from '../hooks/useCredits';
import { cleanPrompt } from '../utils/helpers';
import atawalkBanner from '../assets/atawalk-banner.jpg';
import DownloadButton from '../components/DownloadButton';
import Loader from '../components/Loader';
import GeneratedImages from '../components/GeneratedImages';

function LeonardoPage() {
  const [generatedImages, setGeneratedImages] = useState([]);
  const [params, setParams] = useState({
    prompt: '',
    alchemy: true,
    promptMagic: true,
    guidance_scale: 10,
    height: 512,
    width: 1024,
    num_images: 1,
    presetStyle: 'PHOTOGRAPHY',
    num_inference_steps: 55,
    photoRealStrength: 0.55,
    negative_prompt: '',
    modelId: '5c232a9e-9061-4777-980a-ddc8e65647c6',
  });
  const [error, setError] = useState('');
  const { loading, generateFromText } = useApi();
  const { refreshCredits } = useCredits();

  const handleGenerateImage = async () => {
    if (!params.prompt.trim()) {
      setError('Please enter a prompt.');
      return;
    }

    try {
      const cleanedParams = {
        ...params,
        prompt: cleanPrompt(params.prompt),
        negative_prompt: cleanPrompt(params.negative_prompt)
      };
      
      console.log('Sending params:', cleanedParams);
      const result = await generateFromText(cleanedParams);
      setGeneratedImages(result.generatedImages);
      refreshCredits();
      setError('');
    } catch (error) {
      console.error('Full error details:', error);
      setError(`Generation failed: ${error.message || 'Unknown error'}`);
      setGeneratedImages([]);
    }
  };

  return (
    <>
      {loading && <Loader prompt={params.prompt} />}
      <div className="min-h-screen">
        <div className="mx-auto max-w-4xl px-4 py-8 text-white">
          <div className="mb-8">
            <img 
              src={atawalkBanner} 
              alt="Atawalk Banner" 
              className="w-full rounded-lg shadow-lg mb-8"
            />
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight">Creative Covered Bridge Generator</h1>
              <p className="mt-2 text-xl text-white/60">Transform your bridge with AI-powered creativity</p>
            </div>
          </div>

          {error && (
            <div className="mb-4 p-2 bg-red-100 text-red-700 border border-red-400 rounded">
              {error}
            </div>
          )}

          <div className="space-y-6">
            <div className="space-y-4">
              <div>
                <label className="mb-2 block text-sm font-medium">Creative Prompt</label>
                <textarea
                  name="prompt"
                  value={params.prompt}
                  onChange={(e) => setParams(prev => ({ ...prev, prompt: e.target.value }))}
                  placeholder="Describe how you want your covered bridge to look..."
                  className="w-full rounded-lg border border-white/20 bg-white/10 p-4 text-white placeholder:text-white/40 focus:border-white/30 focus:ring-white/20"
                  rows={4}
                />
                <Link to="/prompt-guide" className="text-blue-400 hover:underline mt-2 block">
                  Need help with prompts? Click here for the guide.
                </Link>
              </div>

              <div className="mt-4">
                <LeonardoParams params={params} setParams={setParams} />
              </div>

              <button
                onClick={handleGenerateImage}
                disabled={!params.prompt.trim() || loading}
                className="w-full rounded-lg bg-gradient-to-r from-purple-500 to-pink-500 px-5 py-3 text-center text-sm font-medium text-white hover:from-purple-600 hover:to-pink-600 focus:outline-none focus:ring-4 focus:ring-purple-300 disabled:cursor-not-allowed disabled:opacity-50"
              >
                {loading ? 'Generating...' : 'Generate Images'}
              </button>
            </div>
          </div>
        </div>

        {!loading && generatedImages.length > 0 && (
          <GeneratedImages images={generatedImages} />
        )}
      </div>
    </>
  );
}

export default LeonardoPage;
