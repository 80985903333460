import React, { useState } from 'react';
import MaterialSelector from '../components/MaterialSelector';
import ImageUploader from '../components/ImageUploader';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import { useApi } from '../hooks/useApi';
import GeneratedImages from '../components/GeneratedImages';
import { useCredits } from '../hooks/useCredits';

function HomePage() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [coveringMaterial, setCoveringMaterial] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const { loading, error, processImage } = useApi();
  const { refreshCredits } = useCredits();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedImage(file);
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleMaterialChange = (value) => {
    setCoveringMaterial(value);
  };

  const handleProcessImage = async () => {
    try {
      const result = await processImage(uploadedImage, coveringMaterial);
      setGeneratedImages(result.generatedImages || result.processedImageUrl);
      refreshCredits();
    } catch (error) {
      console.error('Error processing image:', error);
    }
  };

  const getLoadingPrompt = () => {
    return `Adding a ${coveringMaterial} cover to your bridge...`;
  };

  return (
    <>
      {loading && <Loader prompt={getLoadingPrompt()} />}
      <div className="min-h-screen">
        <div className="mx-auto max-w-2xl px-4 py-8 text-white">
          <div className="mb-8 text-center">
            <h1 className="text-4xl font-bold tracking-tight">Covered Bridge Generator</h1>
            <p className="mt-2 text-xl text-white/60">Transform your bridge with a realistic cover</p>
          </div>
          
          <ErrorMessage message={error} />

          <div className="mb-6">
            <label className="mb-2 block text-sm font-medium">Upload Bridge Image</label>
            <ImageUploader onFileChange={handleFileChange} />
          </div>

          {previewImage && (
            <div className="mb-6 overflow-hidden rounded-lg bg-white/10 p-4">
              <img
                src={previewImage}
                alt="Uploaded Bridge"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          )}

          {uploadedImage && (
            <div className="space-y-6">
              <MaterialSelector onMaterialChange={handleMaterialChange} selectedMaterial={coveringMaterial} />
              <button
                onClick={handleProcessImage}
                disabled={!uploadedImage || !coveringMaterial.trim() || loading}
                className="w-full rounded-lg bg-primary px-5 py-3 text-center text-sm font-medium text-black hover:bg-primary/90 hover:font-bold focus:outline-none focus:ring-4 focus:ring-primary disabled:cursor-not-allowed disabled:opacity-50"
              >
                {loading ? 'Generating...' : 'Generate Covered Bridge'}
              </button>
            </div>
          )}
        </div>

        {!loading && generatedImages.length > 0 && (
          <GeneratedImages images={generatedImages} />
        )}
      </div>
    </>
  );
}

export default HomePage;
