import React from 'react';

function ErrorMessage({ message }) {
  if (!message) return null;
  
  return (
    <div className="mb-4 p-2 bg-red-100 text-red-700 border border-red-400 rounded">
      {message}
    </div>
  );
}

export default ErrorMessage; 