import { useState, useCallback } from 'react';
import { useApi } from './useApi';

export const useCredits = () => {
  const [credits, setCredits] = useState(null);
  const { getUserInfo } = useApi();

  const refreshCredits = useCallback(async () => {
    try {
      const userInfo = await getUserInfo();
      const apiCredits = userInfo.user_details[0].apiSubscriptionTokens;
      setCredits(apiCredits);
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  }, [getUserInfo]);

  return { credits, refreshCredits };
}; 