import React from 'react';
import { Slider } from './ui/Slider';

const LeonardoParams = ({ params, setParams }) => {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setParams((prevParams) => ({
      ...prevParams,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSliderChange = (name, value) => {
    setParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const inputClasses = "w-full rounded-lg border border-white/20 bg-white/10 p-2 text-white placeholder:text-white/40 focus:border-white/30 focus:ring-white/20 appearance-none";
  const labelClasses = "mb-2 block text-sm font-medium text-white";
  const helpTextClasses = "mt-1 text-sm text-white/60";

  return (
    <div className="relative space-y-6 rounded-lg border border-white/20 bg-white/5 p-6">
      <div className="absolute top-2 right-2">
        <span className="text-[#FF6700] text-sm italic">Optional Filters</span>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {/* Negative Prompt */}
        <div>
          <label className={labelClasses}>Negative Prompt</label>
          <textarea
            name="negative_prompt"
            value={params.negative_prompt}
            onChange={handleChange}
            className={inputClasses}
            rows={2}
            placeholder="What to exclude from the generation..."
          />
        </div>

        {/* Style Selection */}
        <div>
          <label className={labelClasses}>Preset Style</label>
          <div className="relative">
            <select
              name="presetStyle"
              value={params.presetStyle}
              onChange={handleChange}
              className={inputClasses}
            >
              <option value="DYNAMIC">Dynamic</option>
              <option value="CREATIVE">Creative</option>
              <option value="ENVIRONMENT">Environment</option>
              <option value="PHOTOGRAPHY">Photography</option>
              <option value="RENDER_3D">3D Render</option>
              <option value="RAYTRACED">Raytraced</option>
              <option value="SKETCH_BW">Sketch B&W</option>
              <option value="SKETCH_COLOR">Sketch Color</option>
              <option value="VIBRANT">Vibrant</option>
            </select>
          </div>
        </div>

        {/* Number of Images */}
        <div>
          <div className="flex justify-between items-center">
            <label className={labelClasses}>Number of Images (1-8)</label>
            <span className="text-sm text-white/80">{params.num_images}</span>
          </div>
          <div className="space-y-2">
            <Slider
              value={[params.num_images]}
              onValueChange={([value]) => handleSliderChange('num_images', value)}
              min={1}
              max={8}
              step={1}
              className="w-full"
            />
            <p className={helpTextClasses}>Number of variations to generate</p>
          </div>
        </div>

        {/* Guidance Scale Slider */}
        <div>
          <div className="flex justify-between items-center">
            <label className={labelClasses}>Guidance Scale (0.5-20)</label>
            <span className="text-sm text-white/80">{params.guidance_scale.toFixed(1)}</span>
          </div>
          <div className="space-y-2">
            <Slider
              value={[params.guidance_scale]}
              onValueChange={([value]) => handleSliderChange('guidance_scale', value)}
              min={0.5}
              max={20}
              step={0.1}
              className="w-full"
            />
            <p className={helpTextClasses}>How strongly the generation should reflect the prompt</p>
          </div>
        </div>

        {/* Inference Steps */}
        <div>
          <div className="flex justify-between items-center">
            <label className={labelClasses}>Inference Steps (10-60)</label>
            <span className="text-sm text-white/80">{params.num_inference_steps}</span>
          </div>
          <div className="space-y-2">
            <Slider
              value={[params.num_inference_steps]}
              onValueChange={([value]) => handleSliderChange('num_inference_steps', value)}
              min={10}
              max={60}
              step={1}
              className="w-full"
            />
            <p className={helpTextClasses}>Higher values may give better results but take longer</p>
          </div>
        </div>

        {/* PhotoReal Strength */}
        <div>
          <div className="flex justify-between items-center">
            <label className={labelClasses}>PhotoReal Strength</label>
            <span className="text-sm text-white/80">
              {params.photoRealStrength === 0.45 ? 'High' : 
               params.photoRealStrength === 0.5 ? 'Medium' : 'Low'}
            </span>
          </div>
          <div className="space-y-2 relative">
            <select
              value={params.photoRealStrength}
              onChange={(e) => handleSliderChange('photoRealStrength', parseFloat(e.target.value))}
              className="w-full rounded-lg border border-white/20 bg-white/10 p-2 text-white"
            >
              <option value={0.55}>Low</option>
              <option value={0.5}>Medium</option>
              <option value={0.45}>High</option>
            </select>
            <p className={helpTextClasses}>Controls the photorealistic quality of the generated image</p>
          </div>
        </div>

        {/* Toggles */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="alchemy"
                checked={params.alchemy}
                onChange={handleChange}
                className="rounded border-white/20 bg-white/10"
              />
              <span className="text-sm text-white">Enable Alchemy</span>
            </label>
          </div>
          
          <div>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="promptMagic"
                checked={params.promptMagic}
                onChange={handleChange}
                className="rounded border-white/20 bg-white/10"
              />
              <span className="text-sm text-white">Prompt Magic</span>
            </label>
          </div>
        </div>

        {/* Image Dimensions */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex justify-between items-center">
              <label className={labelClasses}>Width</label>
            </div>
            <select 
              name="width" 
              value={params.width} 
              onChange={handleChange} 
              className={inputClasses}
            >
              <option value={512}>512px</option>
              <option value={640}>640px</option>
              <option value={1024}>1024px</option>
              <option value={1280}>1280px</option>
              <option value={1536}>1536px</option>
            </select>
          </div>

          <div>
            <div className="flex justify-between items-center">
              <label className={labelClasses}>Height</label>
            </div>
            <select 
              name="height" 
              value={params.height} 
              onChange={handleChange} 
              className={inputClasses}
            >
              <option value={512}>512px</option>
              <option value={640}>640px</option>
              <option value={1024}>1024px</option>
              <option value={1280}>1280px</option>
              <option value={1536}>1536px</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeonardoParams;
