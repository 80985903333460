import React, { useState, useEffect } from 'react';
import UpdateIndicator from './UpdateIndicator';

function CreditTracker({ credits }) {
  const [showIndicator, setShowIndicator] = useState(false);
  const totalMonthlyLimit = 3500;

  useEffect(() => {
    setShowIndicator(true);
  }, [credits]);

  return (
    <div className="group/credits relative flex items-center space-x-2 rounded-lg bg-accent/20 px-3 py-2">
      <svg 
        className="h-5 w-5 text-accent" 
        fill="none" 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth="2" 
        viewBox="0 0 24 24" 
        stroke="currentColor"
      >
        <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <span className="text-sm font-medium text-accent">
        <span className='font-bold hover:text-white'>{credits}</span><span className='text-xs'> / {totalMonthlyLimit} monthly credits</span> 
      </span>
      {showIndicator && <UpdateIndicator />}
    </div>
  );
}

export default CreditTracker; 