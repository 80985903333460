import React from 'react';

const PromptGuide = () => {
  return (
    <div className="mx-auto max-w-4xl text-white p-6">
      <h1 className="text-4xl font-bold mb-4">Prompt Guide for Leonardo AI (Vision XL Model)</h1>
      <p className="mb-4">Learn how to craft effective prompts for generating images using Leonardo AI's Vision XL model.</p>
      
      <h2 className="text-2xl font-semibold mt-6">1. Start with a Clear Subject</h2>
      <p>Define the subject of the image clearly.</p>
      <p>Example: "A majestic lion standing on a rock"</p>

      <h2 className="text-2xl font-semibold mt-6">2. Describe the Image Type</h2>
      <p>Specify the type of image you want to generate.</p>
      <p>Examples: "A photograph of...", "A digital painting of...", etc.</p>

      <h2 className="text-2xl font-semibold mt-6">3. Add Relevant Details</h2>
      <p>Include details about appearance, surroundings, and actions.</p>
      <p>Example: "A red barn with peeling paint in a forest with tall pine trees."</p>

      <h2 className="text-2xl font-semibold mt-6">4. Use Adjectives to Enhance Descriptions</h2>
      <p>Adjectives help define the style, mood, and atmosphere.</p>
      <p>Example: "A vibrant sunset over a calm ocean."</p>

      <h2 className="text-2xl font-semibold mt-6">5. Specify Artistic Style (Optional)</h2>
      <p>Include a particular artistic style if desired.</p>
      <p>Example: "In the style of Van Gogh's Starry Night."</p>

      <h2 className="text-2xl font-semibold mt-6">6. Add Camera Settings or Viewpoints (Optional)</h2>
      <p>Include details about camera angles, lighting conditions, or shot types.</p>

      <h2 className="text-2xl font-semibold mt-6">7. Use Modifiers for Fine-Tuning</h2>
      <p>Modifiers help refine specific aspects of your image.</p>

      <h2 className="text-2xl font-semibold mt-6">8. Negative Prompts (Optional)</h2>
      <p>Specify what you don’t want in your image.</p>

      <h2 className="text-2xl font-semibold mt-6">9. Keep It Concise but Detailed</h2>
      <p>Aim for balance—be specific without overwhelming the AI.</p>

      <h2 className="text-2xl font-semibold mt-6">10. Experiment with Variations</h2>
      <p>Encourage users to tweak their prompts slightly between generations.</p>

      <h2 className="text-2xl font-semibold mt-6">Example Prompts for Inspiration</h2>
      <p>Photorealistic Landscape: "A photorealistic image of a calm lake surrounded by autumn trees reflecting on the water at sunset."</p>
      <p>Fantasy Creature: "An illustration of a dragon flying over a medieval castle during a thunderstorm."</p>

      <h2 className="text-2xl font-semibold mt-6">Prompt Structure Template</h2>
      <p>[Subject] + [Image Type] + [Details/Description] + [Art Style/Modifiers] + [Camera Settings/Lighting] (optional)</p>
      <p>Example: "A majestic lion standing on a rock in the savannah during sunset, photorealistic style, golden hour lighting."</p>

      <h2 className="text-2xl font-semibold mt-6">Additional Tips</h2>
      <p>Test & Iterate: Encourage users to test their prompts multiple times and adjust based on results.</p>
    </div>
  );
};

export default PromptGuide; 