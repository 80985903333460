import React from 'react';

function DownloadButton({ imageUrl, index, className }) {
  return (
    <a
      href={imageUrl}
      download={`covered_bridge_${index + 1}.jpg`}
      target="_blank"
      rel="noopener noreferrer"
      className={`absolute bottom-4 right-4 rounded-full bg-primary p-2 text-surface hover:bg-primary-dark transition-colors duration-200 ${className}`}
      title="Download Image"
    >
      <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
      </svg>
    </a>
  );
}

export default DownloadButton; 