const config = {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:3001',
  ENV: process.env.NODE_ENV || 'development',
  APP_NAME: 'Bridge Cover Generator',
  API_ENDPOINTS: {
    PROCESS_IMAGE: '/api/process-image',
    GENERATE_IMAGE: '/api/generate-image',
    HEALTH: '/api/health',
    USER_INFO: '/api/user-info',
    GENERATE_FROM_TEXT: '/api/generate-from-text',
    GALLERY_RECENT: '/api/gallery/recent'
  }
};

export default config; 