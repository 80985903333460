import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CreditTracker from './CreditTracker';
import { useCredits } from '../hooks/useCredits';
import AtawalkLogo from '../assets/Atawalk-logo-dark-theme.png';

const navVariants = {
  hidden: { y: -20, opacity: 0 },
  visible: { 
    y: 0, 
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20,
      mass: 1
    }
  }
};

const linkVariants = {
  normal: { scale: 1 },
  hover: { 
    scale: 1.05,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 10
    }
  }
};

const Navbar = () => {
  const location = useLocation();
  const { credits, refreshCredits } = useCredits();

  useEffect(() => {
    refreshCredits();
  }, [refreshCredits]);

  useEffect(() => {
    const handleClick = () => {
      refreshCredits();
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [refreshCredits]);

  return (
    <motion.nav
      className="sticky top-0 z-50 w-full bg-background/95 backdrop-blur-sm shadow-md border-b-2 border-primary/20"
      initial="hidden"
      animate="visible"
      variants={navVariants}
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="py-4">
          <div className="flex items-center justify-between">
            {/* Logo Section */}
            <motion.div 
              className="flex-shrink-0"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to="/" className="flex items-center">
                <motion.img 
                  src={AtawalkLogo} 
                  alt="Atawalk Logo" 
                  className="h-8 w-auto"
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 400 }}
                />
              </Link>
            </motion.div>

            {/* Navigation Links */}
            <div className="flex items-center space-x-8">
              <div className="flex space-x-4">
                <AnimatePresence mode="wait">
                  {['/', '/creative-bridge', '/realistic-bridge'].map((path) => (
                    <motion.div
                      key={path}
                      variants={linkVariants}
                      whileHover="hover"
                      whileTap={{ scale: 0.95 }}
                    >
                      <Link
                        to={path}
                        className={`rounded-md px-3 py-2 text-sm font-medium transition-colors ${
                          location.pathname === path
                            ? 'bg-accent text-background'
                            : 'text-surface hover:bg-accent/20 hover:text-surface'
                        }`}
                      >
                        {path === '/' ? 'Gallery' : 
                         path === '/creative-bridge' ? 'Creative Generation' : 
                         'Realistic Generation'}
                      </Link>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
              {credits !== null && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ type: "spring", stiffness: 200, damping: 20 }}
                >
                  <CreditTracker credits={credits} />
                </motion.div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

export default Navbar;
