import config from '../config';

class ApiService {
  constructor() {
    this.defaultHeaders = {
      'Accept': 'application/json',
    };
    this.timeout = 30000; // 30 seconds
  }

  async fetchWithTimeout(url, options) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), this.timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      clearTimeout(timeoutId);
      if (error.name === 'AbortError') {
        throw new Error('Request timeout');
      }
      throw error;
    }
  }

  async handleResponse(response) {
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || `HTTP error! status: ${response.status}`);
    }
    return data;
  }

  async processImage(imageFile, style) {
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('style', style);

    try {
      const response = await this.fetchWithTimeout(`${config.API_URL}${config.API_ENDPOINTS.PROCESS_IMAGE}`, {
        method: 'POST',
        body: formData,
      });
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error processing image:', error);
      throw error;
    }
  }

  async generateImage(imageFile, params) {
    const formData = new FormData();
    formData.append('image', imageFile);
    
    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
    });

    try {
      const response = await this.fetchWithTimeout(`${config.API_URL}${config.API_ENDPOINTS.GENERATE_IMAGE}`, {
        method: 'POST',
        body: formData,
      });
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error generating image:', error);
      throw error;
    }
  }

  async getUserInfo() {
    try {
      const response = await this.fetchWithTimeout(`${config.API_URL}${config.API_ENDPOINTS.USER_INFO}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching user info:', error);
      throw error;
    }
  }

  async generateFromText(params) {
    try {
      const response = await this.fetchWithTimeout(
        `${config.API_URL}${config.API_ENDPOINTS.GENERATE_FROM_TEXT}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      );
      
      const data = await response.json();
      
      if (!response.ok) {
        if (data.message && data.message.includes('must be related to generating a bridge')) {
          throw new Error('Please ensure your prompt is about generating a bridge image');
        }
        throw new Error(data.message || 'Failed to generate image');
      }
      
      return data;
    } catch (error) {
      console.error('Error in generateFromText:', error);
      throw error;
    }
  }

  async getRecentImages() {
    try {
      const response = await this.fetchWithTimeout(`${config.API_URL}${config.API_ENDPOINTS.GALLERY_RECENT}`);
      return this.handleResponse(response);
    } catch (error) {
      console.error('Error fetching recent images:', error);
      throw error;
    }
  }
}

const apiService = new ApiService();
export default apiService;
