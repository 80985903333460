import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LeonardoPage from './pages/LeonardoPage';
import PromptGuide from './pages/PromptGuide';
import GalleryPage from './pages/GalleryPage';
import Navbar from './components/Navbar';
import { useCredits } from './hooks/useCredits';
import './App.css';

function AppContent() {
  const { refreshCredits } = useCredits();
  const location = useLocation();

  React.useEffect(() => {
    refreshCredits();
  }, [location, refreshCredits]);

  return (
    <div className="min-h-screen bg-background">
      <div className="min-h-screen">
        <Navbar />
        <div className="mx-auto w-11/12 max-w-6xl pt-24">
          <div className="rounded-lg bg-background-light p-6 shadow-xl">
            <Routes>
              <Route path="/" element={<GalleryPage />} />
              <Route path="/creative-bridge" element={<LeonardoPage />} />
              <Route path="/realistic-bridge" element={<HomePage />} />
              <Route path="/prompt-guide" element={<PromptGuide />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
