import { useState, useCallback } from 'react';
import apiService from '../services/api';

export const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const processImage = useCallback(async (imageFile, style) => {
    setLoading(true);
    setError(null);
    try {
      const result = await apiService.processImage(imageFile, style);
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const generateImage = useCallback(async (imageFile, params) => {
    setLoading(true);
    setError(null);
    try {
      const result = await apiService.generateImage(imageFile, params);
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const getUserInfo = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await apiService.getUserInfo();
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const generateFromText = async (params) => {
    try {
      setLoading(true);
      console.log('API Request params:', params);
      const response = await fetch('/api/generate-from-text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('API Error Response:', errorData);
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    processImage,
    generateImage,
    getUserInfo,
    generateFromText,
  };
}; 