import React from 'react';
import { motion } from 'framer-motion';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.4,
      ease: "easeOut"
    }
  }
};

function MaterialSelector({ onMaterialChange, selectedMaterial }) {
  const materials = [
    { id: 'wood', name: 'Wood', description: 'Classic and warm, available in Oak, Pine, Cedar, Douglas Fir.' },
    { id: 'stone', name: 'Stone', description: 'Sturdy and timeless, often used in foundations.' },
    { id: 'steel', name: 'Steel', description: 'Modern and strong, used for structural support.' },
    { id: 'concrete', name: 'Concrete', description: 'Durable and robust, often used in bases.' },
    { id: 'brick', name: 'Brick', description: 'Rarely used, adds a decorative touch.' },
  ];

  return (
    <motion.div 
      className="space-y-4"
      initial="hidden"
      animate="show"
      variants={container}
    >
      <motion.label 
        className="mb-2 block text-sm font-medium text-white"
        variants={item}
      >
        Select Bridge Cover Material
      </motion.label>
      <motion.div 
        className="grid grid-cols-1 gap-4 sm:grid-cols-3"
        variants={container}
      >
        {materials.map((material) => (
          <motion.div
            key={material.id}
            onClick={() => onMaterialChange(material.id)}
            className={`cursor-pointer rounded-lg border p-4 ${
              selectedMaterial === material.id
                ? 'border-brown-700 border-2 bg-white/30 text-brown-700'
                : 'border-white/20 bg-white/10'
            }`}
            variants={item}
            whileHover={{ 
              scale: 1.02,
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              transition: { duration: 0.2 }
            }}
            whileTap={{ scale: 0.98 }}
            animate={{
              backgroundColor: selectedMaterial === material.id ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0.1)",
              transition: { duration: 0.3 }
            }}
            style={{
              backgroundImage: selectedMaterial === material.id ? `url('/path/to/${material.id}-texture.jpg')` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <motion.h3 
              className="mb-1 text-lg font-medium text-white"
              layout
            >
              {material.name}
            </motion.h3>
            <motion.p 
              className="text-sm text-white/60"
              layout
            >
              {material.description}
            </motion.p>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
}

export default MaterialSelector; 