import React, { useState, useEffect } from 'react';

function UpdateIndicator() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (!visible) return null;

  return (
    <div className="flex items-center space-x-1">
      <div className="h-2 w-2 rounded-full bg-green-500 animate-pulse" />
    </div>
  );
}

export default UpdateIndicator; 