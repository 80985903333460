import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import apiService from '../services/api';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100
    }
  }
};

function GalleryPage() {
  const [images, setImages] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await apiService.getRecentImages();
        setImages(response.images);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-8 text-white">
        Recent Generations
      </h1>
      
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {images.map((image, index) => (
          <motion.div
            key={image._id}
            className="relative rounded-lg overflow-hidden"
            variants={item}
            whileHover={{ scale: 1.05 }}
            onHoverStart={() => setHoveredImage(image._id)}
            onHoverEnd={() => setHoveredImage(null)}
          >
            <img
              src={image.imageUrl}
              alt={`Generated ${index + 1}`}
              className="w-full h-64 object-cover"
            />
            
            {hoveredImage === image._id && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="absolute inset-0 bg-black bg-opacity-75 p-4 flex flex-col justify-end"
              >
                <p className="text-white text-sm mb-2">
                  {image.prompt}
                </p>
                <div className="text-white/60 text-xs">
                  <p>Style: {image.metadata.presetStyle}</p>
                  <p>Size: {image.metadata.width}x{image.metadata.height}</p>
                  <p>Created: {new Date(image.createdAt).toLocaleDateString()}</p>
                </div>
              </motion.div>
            )}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default GalleryPage; 